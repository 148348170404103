<template>
<v-container style="padding: 15px;" dense>
    <v-row>
        <v-col>
            <h1><Str index="dashboard.user_progress.title"/></h1>
        </v-col>
    </v-row>
    <v-row class="justify-space-evenly" align="center">
        <v-col cols="1" v-if="$vuetify.breakpoint.width>=1640"></v-col>
        <div style="text-align: center;">            
            <v-progress-circular
                :rotate="-90"
                :size="190"
                :width="23"
                :value="progress"
                color="green"
            >
                <center style="color: black;">
                    <h1 style="font-size: 2em;">{{ progress }}%</h1>
                    <sup><Str index="dashboard.user_progress.credits_completed"/></sup>
                </center>
            </v-progress-circular>
        </div>
        <div style="text-align: center;" :class="[{'pa-4':$vuetify.breakpoint.width<425}]">
            <v-img width="60" :src="Badge" style="margin: 0 auto;"/>
            <Str index="dashboard.user_progress.prt_1"/>&nbsp;<strong>{{ credits.videos.earned }}&nbsp;<Str index="dashboard.user_progress.prt_2"/></strong><br/>
            <Str index="dashboard.user_progress.prt_3"/>&nbsp;<strong>{{ maxCredits }}</strong>&nbsp;<Str index="dashboard.user_progress.prt_4"/>
            <br>
            & <strong> {{ $store.getters.CreditController && $store.getters.CreditController.submissions[$store.getters.CreditController.followUp.questionSetId] ? 1 : 0 }} </strong>
            <!-- credit(s) out of  -->
            <Str index="dashboard.user_progress.prt_5"/>
            <strong> {{ $store.getters.CreditController ? $store.getters.CreditController.followUp.creditValue : 0 }} </strong>
            <!-- eligible pledge credit. -->
            <Str index="dashboard.user_progress.prt_6"/>
            <div class="debug" v-if="debug" style="text-align: left;">
                <ul>
                    <li>Max: {{ maxCredits }}</li>
                    <li>queuedCredits: {{queuedCredits}}</li>
                    <li>usedCredits: {{usedCredits}}</li>
                    <li>totalCredits: {{totalCredits}}</li>
                    <json-viewer :value="credits"/>
                </ul>
            </div>
        </div>
        <v-col cols="1" v-if="$vuetify.breakpoint.width>=1640"></v-col>
    </v-row>
    <v-row>
        <v-col style="text-align: center;">
            <h3><Str index="dashboard.user_progress.videos.title"/></h3>
            <span class="custom-color">{{ VideoController.watched.length }}</span>&nbsp;<Str index="dashboard.user_progress.videos.watched"/>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import Badge from './assets/ic-credits-badge.svg'
export default {
    props: {
        VideoController: {
            type: Object,
            required: true
        },
        CreditController: {
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        Badge(){
            return Badge
        },
        progress(){
            let credits = this.credits
            // let videos = this.VideoController.videos
            // let watched = this.VideoController.watched
            // let progress = ( (watched.length) / (videos.length) ) * 100

            let maxCredits = credits.videos.max + credits.followUp.max
            let earnedCredits = credits.videos.earned + credits.followUp.earned
            let progress = (earnedCredits / maxCredits) * 100
            

            return Math.round(progress)
        },
        maxCredits(){
            let CreditController = this.CreditController
            return CreditController.maxCredits
        },
        queuedCredits(){
            let CreditController = this.CreditController
            return CreditController.queuedCredits
        },
        usedCredits(){
            let CreditController = this.CreditController
            return CreditController.usedCredits
        },
        credits(){
            let CreditController = this.CreditController
            return CreditController.credits
        },
        totalCredits(){
            let CreditController = this.CreditController
            return CreditController.totalCredits
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
  font-size: 1.2em;
}
.custom-color{
    color: $green;
    font-weight: bold;
    font-size: 1.2em;
}
</style>