<template>
<div class="certificate-container" v-if="$store.getters.LanguageController.language==='fr-ca'">
    <div class="certificate">
        <div class="certificate-header">
            <img :src="Logo" style="width: 150px;"/>
            <div style="align-self: center; padding: 0px 35px 0px 35px;">
                <h1 style="color: #C9136E; font-size: 3em; line-height: 1.2em;">Certificat de participation</h1>
                <h2 style="font-size: 2em;">Perfectionnement professionnel continu</h2>
            </div>
        </div>
        <div class="certificate-body">
            <p>
                La présente a pour but de certifier que​
            </p>
            <p>
                <strong>{{certificate.name}}</strong>
            </p>
            <p>
                a terminé le programme de perfectionnement professionnel continu intitulé​
            </p>  
            <p style="color: darkred;">
                AGIR (la médecine de l’obésité)
            </p>
            <p>
                et a obtenu {{creditsFormatted}} crédits Mainpro+
            </p>
            <p>
                CERT+ SESSION {{cert.id}}<br>
                Programme d’autoapprentissage en ligne disponible du {{cert.dates_fr}}
            </p>
        </div>
        <div class="certificate-body-bottom">
            <div style="text-align: center; align-self: center;padding-left: 25px;">
                <img :src="Signature" style="width: 150px;"/><br>
                Jeffrey Habert, MD, CCFP, FCFP<br>
                Directeur du réseau CPD
            </div>
            <div style="align-self: center;">
                Crédits pour médecins de famille :<br>
                Ce programme d’autoapprentissage a été certifié par le Collège des<br>
                médecins de famille du Canada pour un maximum de 4,5 crédits Mainpro+<sup>®</sup>.​<br>
            </div>
        </div>
        <div class="certificate-body-subtext">
            Réclamer vos crédits : Veuillez soumettre vos crédits pour cette activité en ligne à www.cfpc.ca/login. Veuillez conserver une preuve de votre participation pendant six ans au cas où vous seriez sélectionné pour participer à la validation ou à la vérification du crédit.
        </div>
        <div class="certificate-body-support font-weight-bold">
            Ce programme a bénéficié d’un soutien financier de la part de Bausch Health sous la forme d’une subvention éducative illimitée.​
        </div>            
        <img :src="Footer" style="width: 100%; position: relative; top: 8px;"/>
        <span style="font-size: 8pt; position: absolute; bottom: 3px; right: 5px; color: white;">{{certificate.date.toLocaleDateString($store.getters.LanguageController.language, {weekday: "short",year: "numeric",month: "short",day: "numeric"})}}</span>
    </div>
    <section v-html="css"/>     
</div>    
<div class="certificate-container" v-else>   
    <div class="certificate">
        <div class="certificate-header">
            <img :src="Logo" style="width: 150px;"/>
            <div style="align-self: center; padding: 0px 35px 0px 35px;">
                <h1 style="color: #C9136E; font-size: 3em; line-height: 1.2em;">Certificate of Attendance</h1>
                <h2 style="font-size: 2em;">Continuing Professional Developement</h2>
            </div>
        </div>
        <div class="certificate-body">
            <p>
                This is to certify that
            </p>
            <p>
                <strong>{{certificate.name}}</strong>
            </p>
            <p>
                has completed the Continuing Professional Development program entitled
            </p>  
            <p style="color: darkred;">
                i-ACT in Obesity CME Program
            </p>
            <p>
                and earned {{creditsFormatted}} Mainpro+ credits
            </p>
            <p>
                CERT+ SESSION {{cert.id}}<br>
                Self-learning online program available from {{cert.dates_en}}
            </p>
        </div>
        <div class="certificate-body-bottom">
            <div style="text-align: center; align-self: center;padding-left: 25px;">
                <img :src="Signature" style="width: 150px;"/><br>
                Jeffrey Habert, MD, CCFP, FCFP<br>
                Director at CPD Network
            </div>
            <div style="align-self: center;">
                Credits for Family Physicians: <br>
                This self-learning program has been certified by the College of <br>
                Family Physicians of Canada for up to 4.5 Mainpro+ credits.<br>
            </div>
        </div>
        <div class="certificate-body-subtext">
            Claiming your credits: Please submit your credits for this activity online at www.cfpc.ca/login. Please retain proof of your participation for six years in case you are selected to participate in credit validation or auditing.
        </div>
        <div class="certificate-body-support font-weight-bold">
            This program has received financial support from Bausch Health in the form of an educational grant.​
        </div>            
        <img :src="Footer" style="width: 100%; position: relative; top: 8px;"/>
        <span style="font-size: 8pt; position: absolute; bottom: 3px; right: 5px; color: white;">{{certificate.date.toLocaleDateString($store.getters.LanguageController.language, {weekday: "short",year: "numeric",month: "short",day: "numeric"})}}</span>
    </div>
    <section v-html="css"/>     
</div>    
</template>

<script>
import LogoEn from '@/assets/logo.png'
import LogoFr from '@/assets/logo-fr-ca.png'
import Signature from './assets/signature.png'
import Footer from './assets/footer.png'
export default {
    props: {
        certificate: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    data(){
        return {
            cert: {
                id: 'ID-200757',
                dates_en: 'October 12, 2023 – September 27, 2024​​',
                dates_fr: '12 octobre 2023 au 27 septembre 2024'
            }
        }
    },
    computed: {
        creditsFormatted(){
            if (this.$store.getters.LanguageController.language==='fr-ca'){
                return this.certificate.credits.toString().replace(".", ",")
            }
            
            return this.certificate.credits
        },
        Logo(){
            return this.$store.getters.LanguageController.language==='fr-ca' ? LogoFr : LogoEn
        },
        Signature(){
            return Signature
        },
        Footer(){
            return Footer
        },
        css(){
            // @page{size: landscape;}
            return `
            <style type="text/css">
                .certificate{
                    font-family: "Roboto", sans-serif;
                    margin: 0 auto;
                    width: 1010px;
                    min-height: 656px;
                    border: 1px solid grey;
                    background-color: white;
                    border-radius: 0px;
                    padding: 25px 0px 0px 0px;
                    color: #666666;
                    position: relative;
                }
                .certificate-header{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-bottom: 60px;
                }
                .certificate-body{
                    margin: 25px 0px 10px 0px;
                    text-align: center;
                }
                .certificate-body p{
                    margin: 20px;
                }
                .certificate-body-bottom{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    margin: 45px;
                }
                .certificate-body-subtext{
                    text-align: center;
                    font-size: 8pt;
                    margin: 15px auto;
                    margin-top: 25px;
                    width: 80%;
                }
                .certificate-body-support{
                    text-align: center;
                    font-size: 10pt;
                    margin: 15px auto;
                    margin-top: 25px;
                    width: 80%;
                }    
                ul{
                    margin: 50px auto;
                    list-style: none;
                    max-width: 600px;
                }
                ul li{
                    border-bottom: 1px solid gray;
                }
                .app{
                    border: none !important;
                }
                body{
                    border: none !important;
                }
            </style>
            `
        }        
    }
}
</script>

<style>
.certificate-container{
    padding: 12px 12px 0 12px;
}
</style>