<template>
  <div>
    <v-card style="display:inline-block; margin: 3px;" width="20%" v-for="(user, user_id) in questionAnswers" :key="`text-area-${user_id}`">
        <v-card-text>
            {{ user.answers.value }}
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
    name: "TextareaReport",
    props:{
        meta: {
            Type: Object,
            required: true
        },
        answers: {
            Type: Array,
            required: true
        },
        question:{
            Type: Object,
            required: true
        },
        language:{
            Type: String,
            required: false,
            default: 'en-ca'
        }
    },
    computed:{
        questionAnswers(){
            let data = {}
            let sectionId = this.meta.section.id
            let questionId = this.question.id

            let submissions = this.answers
            for(let i=0; i<submissions.length; i++){
                let record = submissions[i]
                if(!data[record.user_id]){
                    data[record.user_id] = null
                }
                let answer = record.answers[sectionId][questionId]
                data[record.user_id] = answer
            }

            return data
        }
    }
}
</script>

<style>

</style>