<template>
<v-container fluid dense>
  <v-row>
    <v-col>
      <h1><Str index="dashboard.pledge.title"/></h1>
    </v-col>    
  </v-row>
  <v-row v-if="(!QuestionSetController.submissions[1])">
    <v-col class="pa-0" style="margin-bottom: 50px;">
      <v-container fluid dense>
          <v-row class="custom-midgray">
            <v-col align-self="center" cols="1">
              <v-icon :style="{'margin-left':$vuetify.breakpoint.width<500 ? '-10px' : 'initial'}" large color="green">mdi-information</v-icon>              
            </v-col>
            <v-col align-self="center">
              <Str index="dashboard.user pledges.none submitted"/>
            </v-col>
          </v-row>
        </v-container>      
    </v-col>
  </v-row>
  <template v-else>
    <v-row style="text-align: center;">
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="3"></v-col>
      <v-col class="px-2"><Str index="dashboard.pledge.initial_pledge"/></v-col>
      <v-col class="px-2"><Str index="dashboard.pledge.follow_up"/></v-col>
    </v-row>
    <v-row style="text-align: center;">
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="3"></v-col>
      <v-col class="px-2" v-for="pledge in pledges" :key="`pledge-column-${pledge.id}`">
        
        <v-btn text v-if="!QuestionSetController.submissions[pledge.id]" :disabled="pledge.id==pledges[1].id && !allowFollowUp" @click="QuestionSetController.gotoQuestionSet(pledge)">
            {{ $store.getters.LabelController.getLabel('pledge.description.'+pledge.description)}}
        </v-btn>
          <template v-else>        
            <v-btn text @click="QuestionSetController.gotoQuestionSet(pledge)">
              <v-icon small>mdi-eye</v-icon> {{ simpleDate(QuestionSetController.submissions[pledge.id].created) }}
            </v-btn>
            <v-btn v-if="debug" class="debug" icon :disabled="QuestionSetController.busy" @click="QuestionSetController.deleteSubmission(pledge.id)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
          </template>


        <div v-if="pledge.id==pledges[1].id && !QuestionSetController.submissions[pledge.id] && !allowFollowUp">
          <sup>{{ followUpReleaseDate.toLocaleDateString($store.getters.LanguageController.language, {weekday: "short",year: "numeric",month: "short",day: "numeric"}) }}</sup>
        </div>          
      </v-col>
    </v-row>
  </template>

    <v-row class="footer pa-0" :style="{'border': (!allowPledge || !allowFollowUp) ? '3px solid #a3c5cd' : 'none'}">
        <v-col v-if="$vuetify.breakpoint.width>399" class="custom-blue" :cols="(!allowPledge || !allowFollowUp) ? 5 : 12"></v-col>
        <v-col class="custom-gray" v-if="!allowPledge || !allowFollowUp">
          <v-btn v-if="allowPledge" @click="QuestionSetController.gotoQuestionSet(pledges[0])" text dark>
            <Str index="dashboard.pledge.cta.take_pledge"/><v-icon>mdi-menu-right</v-icon>
          </v-btn>
          <!-- {{ countdown }} / {{ followUpReleaseDate.toDateString() }} -->
          <v-btn v-else-if="allowFollowUp" @click="QuestionSetController.gotoQuestionSet(pledges[1])" text x-small dark>
            <Str index="dashboard.pledge.cta.complete_followup"/><v-icon>mdi-menu-right</v-icon>
          </v-btn>      
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
  props: {
    QuestionSetController: {
      type: Object,
      required: true
    },
    debug: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created(){
    this.init()
  },
  data(){
    return {
      countdown: null,
      interval: null
    }
  },
  methods: {
    init(){
      let self = this
      self.updateCountdown()
      clearInterval(self.interval)
      self.interval = setInterval(() => {
        self.updateCountdown()
      }, 5000);
    },
    updateCountdown(){
      let releaseDate = this.followUpReleaseDate
      let now = new Date()
      let diff = releaseDate-now
      this.countdown = diff/1000/60/60/24
    },
    simpleDate(dateString){
      function dd(number){
        if(number<10){
          return `0${number}`
        }
        return number
      }
      let d = new Date(dateString)
      let year = d.getFullYear()
      let month = dd(d.getMonth()+1)
      let date = dd(d.getDate())
      return `${year}-${month}-${date}`
    }
  },
  computed: {
    pledges(){
      let routes = this.$router.options.routes
      let initialPledgeIndex = routes.findIndex((item)=>{return item.name=='Initial Pledge'})
      let followUpIndex = routes.findIndex((item)=>{return item.name=='Follow Up'})
      return [
        this.QuestionSetController.questionSets[routes[initialPledgeIndex].meta.questionSet_id],
        this.QuestionSetController.questionSets[routes[followUpIndex].meta.questionSet_id],
      ]
    },
    allowPledge(){
      let QuestionSetController = this.QuestionSetController
      return !QuestionSetController.submissions[this.pledges[0].id]
    },
    allowFollowUp(){
      let QuestionSetController = this.QuestionSetController
      let pledges = this.pledges
      let countdown = this.countdown
      return (this.debug || (QuestionSetController.submissions[pledges[0].id] && countdown<=0)) && !QuestionSetController.submissions[pledges[1].id]
    },
    followUpReleaseDate(){
      let QuestionSetController = this.QuestionSetController
      let pledge = QuestionSetController.submissions[this.pledges[0].id]
      let d = new Date()
      let releaseDate = new Date(d.setMonth(d.getMonth()+1))
      if(pledge){
        let t1 = new Date(pledge.created)
        t1.setMonth(t1.getMonth()+3)
        t1.setHours(0)
        t1.setMinutes(0)
        t1.setSeconds(0)
        releaseDate = new Date(t1)
      }
      return releaseDate
    }
  }
}
</script>

<style lang="scss" scoped>
h1{
  font-size: 1.2em;
}
.custom-blue{
  text-align: center;
  background-color: $blue;
}
.custom-midgray{
  background-color: $midgray;
}
.custom-gray{
  text-align: center;
  background-color: $gray;
}
</style>