<template>
    <v-card flat>
        <v-row v-if="signUpError" class="custom-error mb-5 pa-3" no-gutters>
            <v-col cols="1" align-self="center">
                <v-icon color="orange" large>mdi-alert</v-icon>
            </v-col>
            <v-col align-self="center">
                <Str :index="signUpErrorMsg"/>
            </v-col>
        </v-row>         
        <section v-if="ui.state=='email'">
            <v-form ref="form" v-model="valid">
                <v-text-field ref="email" prepend-icon="mdi-shield-account-outline" v-model="form.email" :label="$store.getters.LabelController.getLabel('authentication.form.label.email')" required :rules="rules.email"/>
                <v-text-field type="password" v-model="form.password" prepend-icon="mdi-shield-key-outline" :label="$store.getters.LabelController.getLabel('authentication.form.label.password')" required :rules="rules.password">
                    <template v-slot:message="{ message }">
                        <Str :index="message"/>
                    </template>
                </v-text-field>
                <v-checkbox v-model="form.agree" required :rules="rules.casl">
                    <template v-slot:label>
                        <div>
                        <Str index="sign_in_up.consent_platform.part_1"/>
                        &nbsp;<router-link :to="{name:'Terms of Use'}"><Str index="sign_in_up.consent_platform.part_2"/></router-link>
                        &nbsp;<Str index="sign_in_up.consent_platform.part_3"/>
                        &nbsp;<router-link :to="{name:'Privacy Policy'}"><Str index="sign_in_up.consent_platform.part_4"/></router-link>
                        </div>
                    </template>
                    <template v-slot:message="{ message }">
                        <Str :index="message"/>
                    </template>                        
                </v-checkbox>    
                <v-checkbox v-model="form.casl_marketing_ctc" false-value="no" true-value="yes">
                    <template v-slot:label>
                        <div><Str index="sign_in_up.consent_marketing.part_1"/></div>
                    </template>
                </v-checkbox>
            </v-form>
        </section>
        <section v-if="ui.state=='verify'">
            <Str index="authentication.form.label.verify_email"/>
            <v-text-field v-model="form.verificationCode" :label="$store.getters.LabelController.getLabel('authentication.account_verification.label.verification_code')"/>
        </section>

        <v-card-actions>
            <v-spacer/>
            <v-btn v-if="ui.state=='email'" :disabled="!valid" x-large color="blue" dark tile @click="signUp"><Str index="authentication.form.button.next"/></v-btn>
            <v-btn v-if="ui.state=='verify'" x-large color="blue" dark tile @click="ui.state='email'"><Str index="authentication.form.button.back"/></v-btn>
            <v-btn v-if="ui.state=='verify'" x-large color="blue" dark tile @click="verify"><Str index="authentication.form.button.verify"/></v-btn>
        </v-card-actions> 

        <!-- <v-card-text>            
            <v-textarea v-model="temp.verificationString"></v-textarea>
            <v-btn @click="temp.methods.saveVerification">Save</v-btn>
        </v-card-text> -->
        
        <v-snackbar v-model="ui.snackbar.display">
            {{ ui.snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn class="pink-brand" text v-bind="attrs" @click="ui.snackbar.display = false">
                    <Str index="authentication.form.button.close"/>
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>
  
<script>
function tempMethods(component){
  this.saveVerification = async()=>{
      let json = {}
      eval(`json = ${component.temp.verificationString}`)
      let response = await component.sendRequest('post','/api/verification',json)
      console.log('saveVerification', response)
  }
}
export default {
  created(){
      this.form.email = this.model
      this.formRules = new this.formRulesAPI(this)
      this.routeState()
  },
    mounted(){
        let self = this
        // Following resolves ovarlaping browser saved credentials by input field label
        setTimeout(() => {
            window.localStorage.clear();            
            const els = document.querySelectorAll("input:-webkit-autofill")
            console.log(els)
            
            els.forEach((el) => {
                const label = el.parentElement.querySelector("label")
                label.classList.add("v-label--active")
            })
            
            self.$refs.email.focus()
        }, 200)
    },  
  data(){
      return {
          formRules: null,
          valid: false,
          ui: {
              state: 'email',
              snackbar: {
                  display: false,
                  message: null
              }
          },
          form: {
              email: null,
              password: null,
              agree: false,
              verificationCode: null,
              casl_marketing_ctc: 'no'
          },
          rules: {
              email: [
                  v => this.formRules.rules.validEmail(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
              ],
              password: [
                  v => !!v || 'authentication.form.label.required_field',
                  v => this.formRules.rules.answered(v) || 'authentication.form.label.required_field',
                  v => this.formRules.rules.strongPassword(v) || 'authentication.password_complexity'
              ],
              casl: [
                  v => !!v || 'authentication.form.label.required_field'
              ]
          },
          temp: {
              verificationString: null,
              methods: new tempMethods(this)
          },
          signUpError: false,
          signUpErrorMsg: null
      }
  },
  methods: {
      async signUp(){
        let success = await this.controller.signUp({email: this.form.email, password: this.form.password, attributes: {'custom:preferred_language':this.$store.getters.LanguageController.language, 'custom:casl_marketing_ctc':this.form.casl_marketing_ctc}})
        let CampaignController = this.$store.getters.CampaignController
        let campaign = CampaignController.storage().getCampaign()
        let user_id = this.cache.storage.local.get('signUp') && this.cache.storage.local.get('signUp').userSub ? this.cache.storage.local.get('signUp').userSub : null
        if(campaign && user_id){
            await CampaignController.saveLog(campaign,'sign_up',{email: this.form.email, user_id: user_id})
        }
        console.log(this.controller.hubEvent)
        if(success || (this.controller.hubEvent && this.controller.hubEvent.data.code=="UsernameExistsException")){
            // check if for UsernameExistsException account is validated 
            if (this.controller.hubEvent && this.controller.hubEvent.data.code=="UsernameExistsException"){
                let response = await this.sendRequest('get',`/api/verification/state/${this.form.email}`)
                if (response.data.account_confirmed===true){
                    this.signUpErrorMsg = `${this.$store.getters.LabelController.getLabel('authentication.message.signUp.account_exist')} ${this.$store.getters.LabelController.getLabel('authentication.message.signUp.sign_in')}`
                    this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.message.signUp.account_exist')
                    this.signUpError = true
                }
                else{
                    this.ui.state='verify'
                    this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.message.signUp.success')                    
                }
            }
            else {
                this.ui.state='verify'
                this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.message.signUp.success')
            }
        }else{
            this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.message.signUp.failed')
        }
        this.ui.snackbar.display = true
      },
      async verify(){
          let target = this.$router.getRoutes().filter((route)=>{return route.name=='Verify URL'})[0].path.split(':')
          let params = {}
          params[target[1]] = this.form.verificationCode
          this.$router.push({path:`${target[0]}${this.form.verificationCode}`})
          // let success = await this.controller.confirmSignUp({username: this.form.email, verificationCode: this.form.verificationCode})
          // if(success){
          //         let CampaignController = this.$store.getters.CampaignController
          //         let campaign = CampaignController.storage().getCampaign()
          //         if(campaign){
          //             await CampaignController.saveLog(campaign,'verify',{email: this.form.email})
          //         }
          //     this.$router.push({name:'Home'})
          //     this.ui.snackbar.message = 'signUp.verification.success'
          // }else{
          //     this.ui.snackbar.message = 'signUp.verification.failed'
          // }
          // this.ui.snackbar.display = true
      },
      routeState(){
          if(this.routeName=='Verify Email'){
              this.ui.state='verify'
          }            
      }
  },
  computed:{
      controller(){
          return this.$store.getters.UserController
      },
      model(){
          return this.$attrs.value
      },
      routeName(){
          return this.$route.name
      }
  },
  watch: {
      model(){
          this.form.email = this.model
      },
      routeName(){
          this.routeState()
      }    
  }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled{
    background-color: $lightgray !important;
    color: $gray !important;
}

::v-deep .pink-brand {
    color: $pink !important;
}

.custom-error{
    background-color: black;
    color: white;
}
</style>