<template>
  <v-card>
      <v-card-text>
        <h1>{{ Data.value }}</h1>
        <div>
            {{Data.text}}
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        Data: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>