<template>
  <v-container>
    <v-row>
        <v-col>
            <template v-if="debug">
                <v-btn class="debug" @click="controller.randomize" text>Randomize</v-btn>
                <v-btn class="debug" @click="controller.reset" text>Reset</v-btn>
                <v-btn class="debug" @click="controller.delete" text>Delete</v-btn>
            </template>
            <v-form v-model="valid" ref="form" :disabled="disabled">
                <Section v-model="form" v-for="section in questionSet.sections" @resetInput="resetInput" 
                         :rules="rules" :questionSet="questionSet" :section="section" :key="`section-${section.id}`" :language="$store.getters.LanguageController.language"/>
            </v-form>
            <slot name="submit"/>
            <!-- <v-container fluid dense>
                <v-row class="custom-gray pa-0">
                    <v-col class="pa-0">
                        <v-btn @click="$router.push({name:'Dashboard'})" class="back-button" text dark><< Back</v-btn>
                    </v-col>
                    <v-col class="pa-0" style="text-align: right;">
                        <v-btn @click="controller.submit" class="send-button" :disabled="completed || !valid || QuestionSetController.busy" text dark>Send</v-btn>                        
                    </v-col>
                </v-row>
            </v-container> -->

        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Section from './lib/section.vue'
function controller(component){
    let controller = this
    this.validate = ()=>{
            return new Promise((resolve)=>{
                let self = component
                self.$refs.form.validate()
                setTimeout(() => {
                    resolve(self.valid)
                }, 200);
            })
    }
    this.randomize = ()=>{
        let questionSet = component.questionSet
        let sections = questionSet.sections
        for (let s = 0; s < sections.length; s++) {
            const section = sections[s]
            const questions = section.questions

            for(let q=0; q<questions.length; q++){
                const question = questions[q]
                question.controller.randomize()
            }
            
        }
    }
    this.reset = ()=>{
        component.valid = false
        component.$refs.form.reset()
    }
    this.delete = async()=>{
        let response = await component.$store.getters.QuestionSetController.deleteSubmission(component.questionSet.id)
        if(response.status==200){
            controller.reset()
        }
    }
    this.resetValidation = ()=>{
        component.$refs.form.resetValidation()
    }
    this.submit = async()=>{      
        if(await controller.validate()){
            await component.$store.getters.QuestionSetController.submit(component.questionSet,component.form)
            controller.getSubmissions()
        }
    }
    this.getAnswers = ()=>{
        return component.form
    }

    this.getSubmissions = async()=>{
        let QuestionSetController = component.$store.getters.QuestionSetController
        let questionSet = component.questionSet
        let self = component        
        setTimeout(async() => {
        await QuestionSetController.getSubmissions()  
        if(QuestionSetController.submissions[questionSet.id]){
            self.form = QuestionSetController.submissions[questionSet.id].answers
        }
        }, 800);
    }    
}
export default {
    props:{
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        questionSet:{
            type: Object,
            required: true
        }
    },
    async created(){
        let self = this
        if(self.completed){
            setTimeout(() => {
                self.form = self.QuestionSetController.submissions[self.questionSet.id].answers
            }, 0);
        }else{
            await this.controller.getSubmissions()
        }
        this.$emit('setController',this.controller)
    },
    components: {
        Section
    },
    data(){
        return {
            valid: false,
            form: {},
            rules: {
                answered: [(v)=>{if(!v || v.length==0){return this.$store.getters.LabelController.getLabel("authentication.form.label.required_field")} return true}]
            },
            controller: new controller(this)
        }
    },
    methods: {
        resetInput(uid){
            let inputs = this.$refs.form.inputs
            let index = inputs.findIndex((input)=>{return input.$parent._uid==uid})
            let target = inputs[index].$parent
            for(let i=0; i<target.$children.length; i++){
                let input = target.$children[i]
                input.resetValidation()
            }
        }
    },
    computed:{
        QuestionSetController(){
            return this.$store.getters.QuestionSetController
        },
        completed(){
            let QuestionSetController = this.QuestionSetController
            let QuestionSet = this.questionSet
            return QuestionSetController.submissions[QuestionSet.id] ? true : false
        }
    },
    watch: {
        form:{
            deep: true,
            handler(){
                let answers = this.form
                this.$emit('answers',answers)
            }
        },
        valid(){
            this.$emit('validate',this.valid)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>