<template>
  <div style="text-align:center">
    ERROR 404
  </div>
</template>

<script>
export default {
  created(){
    let self = this
    setTimeout(() => {
      self.$router.push({name: 'Home'})
    }, 2000);
  }
}
</script>

<style>

</style>