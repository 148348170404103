<template>
    <v-list dense>
    <v-list-item dense v-for="(section, sectionIndex) in questionSet.sections" :key="`section-${sectionIndex}`">
        <v-list-item-content>
        <v-list-item-title>
            <h3>{{ section.text[language] }}</h3>
        </v-list-item-title>
        <v-list-item-subtitle v-if="section.subtext[language]">
            <span v-html="section.subtext[language]"/>
        </v-list-item-subtitle>
        <v-list dense>
            <v-list-item v-for="(question) in section.questions" :key="`section-${sectionIndex}-question-${question.id}`">
            <v-list-item-content>
                <v-list-item-title>
                <h4>{{ question.text[language] }}</h4>
                </v-list-item-title>
                <!-- <v-list-item-subtitle>
                {{ question.type }}
                </v-list-item-subtitle> -->
                
                <RatingTableReport v-if="question.type=='RatingTable'" :meta="{section, config}" :answers="answers" :question="question" :language="language"/>
                <RadioReport v-else-if="question.type=='Radio'" :meta="{section, config}" :answers="answers" :question="question" :language="language"/>
                <TextareaReport v-else-if="question.type=='Textarea'" :meta="{section, config}" :answers="answers" :question="question" :language="language"/>


                <div v-else>
                <json-viewer :value="question"/>
                </div>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-list-item-content>
    </v-list-item>
    </v-list>  
</template>

<script>
import RatingTableReport from '@/components/QuestionSet/reports/RatingTable'
import RadioReport from '@/components/QuestionSet/reports/Radio'
import TextareaReport from '@/components/QuestionSet/reports/Textarea'
export default {
    name: "List",
    props: {
        config: {
            type: Object,
            required: true
        },
        questionSet: {
            type: Object,
            required: true
        },
        answers: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        RatingTableReport,
        RadioReport,
        TextareaReport
    }
}
</script>

<style>

</style>