<template>
  <v-container class="TermsOfUse">
    <v-row>
        <v-col cols="12" lg="1" class="pt-6" ></v-col>
        <v-col>
            <Str index="TermsOfUse"/>
        </v-col>
        <v-col cols="12" lg="1" class="pt-6" ></v-col>
    </v-row>
  </v-container>  
</template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style lang="scss">
  .TermsOfUse .section{
    color: teal;
    font-weight: bold;
    font-size: 1.2em;
  }
  </style>