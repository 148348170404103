<template>
    <v-container style="margin-top: 50px;">
        <v-row v-if="['Sign In','Sign Up'].includes($route.name)">
            <v-col cols="12" md="5">
                <h1><Str index="sign_in_up.title"/></h1>
                <h2><Str index="sign_in_up.subtitle"/></h2>
                <p><Str index="sign_in_up.parag_1"/></p>
                <p><Str index="sign_in_up.parag_2"/></p>
                <p><Str index="sign_in_up.parag_3"/></p>
            </v-col>
            <v-col cols="12" md="7">
                <v-card>
                    <v-container>
                        <v-row class="header-row">
                            <v-col :class="['header',{'active':$route.name=='Sign In'}]">
                                <router-link :to="{name:'Sign In'}"><Str index="sign_in_up.label.sign_in"/></router-link>                                
                            </v-col>
                            <v-col :class="['header',{'active':$route.name=='Sign Up'}]">
                                <router-link :to="{name:'Sign Up'}"><Str index="sign_in_up.label.sign_up"/></router-link>                                
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <SignIn v-if="$route.name=='Sign In'" v-model="email"/>
                                <SignUp v-if="$route.matched.findIndex((route)=>{return route.name=='Sign Up'})>=0" v-model="email"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>             
            </v-col>
        </v-row>
        <v-row v-else>
            <Verify @success="goAway"/>
        </v-row>
    </v-container>
</template>

<script>
import SignIn from '@/components/Authentication/SignIn.vue'
import SignUp from '@/components/Authentication/SignUp.vue'
import Verify from '@/components/Authentication/Verify.vue'
export default {
    created(){
        this.ui.state = 'signUp.login'
        if(this.verified){
            this.goAway()
        }
    },
    components: {
        SignIn,
        SignUp,
        Verify
    },
    data(){
        return {
            ui: {
                state: undefined
            },
            email: null,
            timer: null
        }
    },
    methods: {
        goAway(){
            this.$router.push({name: 'Dashboard'})
        }
    },
    computed: {
        verified(){
            return this.$store.getters.UserController.authenticated
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    font-size: 4em;
    font-weight: 900;
    color: $pink;
}
.header-row{
    background-color: $gray;
}
.header, .header a{
    color: white;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
}
.active{
    background-color: $pink;
}
</style>