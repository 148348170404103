<template>
    <v-card tile flat v-if="(controller.user.attributes.given_name && controller.user.attributes.given_name.length>0) && (controller.user.attributes.family_name && controller.user.attributes.family_name.length>0)">
        <v-container fluid>
            <v-row dense no-gutters>
                <v-col cols="9" style="text-align: right;" align-self="center">
                    <div>
                        <strong>
                            {{controller.user.attributes.given_name}} {{controller.user.attributes.family_name}}
                        </strong>
                        <div>
                            {{controller.user.attributes.email}}
                        </div>
                        <div>

                            <Str index="dashboard.user_info.last_login"/>&nbsp;
                            {{ $store.getters.lastLogin ? $store.getters.lastLogin.toLocaleDateString($store.getters.LanguageController.language, date_format_options) 
                                : new Date().toLocaleDateString($store.getters.LanguageController.language, date_format_options) }}
                        </div>
                    </div>                    
                </v-col>
                <v-col v-if="$route.name!='Print Pledge' && $route.name!='Print Follow-Up'" style="text-align: center;" align-self="center">
                    <v-btn
                        depressed
                        fab
                        dark
                        color="#666666"
                        @click="display=!display"
                    >
                        {{ controller.user.attributes.given_name.substr(0,1) }}{{ controller.user.attributes.family_name.substr(0,1) }}
                    </v-btn>
                    <v-menu v-model="display" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                depressed
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon small class="mdi-flip-v">
                                    mdi-triangle
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item  class="menuItem" @click="controller.profile.open()">
                                <v-list-item-title><Str index="dashboard.user_info.my_account"/></v-list-item-title>
                            </v-list-item>
                            <v-list-item  class="menuItem" @click="controller.signOut()">
                                <v-list-item-title><Str index="site.common.sign_out"/></v-list-item-title>
                            </v-list-item>
                            <v-list-item  class="menuItem" v-show="allow(['admin','staff','tester','client'])">
                                <v-list-item-title>
                                    <!-- <router-link :to="{name:'Usage Report'}"><Str index="dashboard.user_info.usage_report"/></router-link>-->
                                    <v-btn class="bgcolor-brand-green" small :to="{name:'Usage Report'}"><Str index="dashboard.user_info.usage_report"/></v-btn>
                                </v-list-item-title>
                            </v-list-item>                       
                        </v-list>
                    </v-menu>                    
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    data(){
        return {
            display: false,
            date_format_options: {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric"
            }
        }
    },
    methods: {
        allow(groups){
          return this.controller && this.controller.authorized(groups)
        }        
    },
    computed: {
        controller(){
            return this.$store.getters.UserController
        }
    }
}
</script>

<style lang="scss" scoped>
.menuItem{
    background-color: whitesmoke;
    color: black;
    margin-bottom: 3px;
}
.menuItem:hover{
    background-color: $yellow;
}
</style>