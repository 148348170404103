<template>
  <div class="shape" :style="{color:options.fontcolor}">
    <v-container>
        <v-row>
            <v-col class="content" :class="`custom-${options.backgroundColor}`">
                <slot name="content"/>
            </v-col>
            <v-col cols="1" class="pa-0">
                <div class="triangle" :class="`triangle-${options.backgroundColor}`" :style="{'border-width': `${options.triangle.height}px ${options.triangle.width}px 0 0`}"/>
            </v-col>
        </v-row>
        
        
    </v-container>
  </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.shape{
    display: inline-block;
    position: relative;
    color: black;
    width: 100%;
    z-index: 1;
    // min-height: 400px;
}

.content{
    text-align: left;
    z-index: 1;
}
  
.custom-black{
    background-color: black;
}
  
.custom-gray{
    background-color: $gray;
}

.custom-darkgray{
    background-color: $darkgray;
}

.custom-midgray{
    background-color: $midgray;
}

.custom-lightgray{
    background-color: $lightgray;
}

.custom-pink{
    background-color: $pink;
}

.custom-recommended-pink{
    background-color: $pink;
    padding: 15px 12px 20px 20px;
}

.custom-green{
    background-color: $green;
}

.custom-blue{
    background-color: $blue;
}

.custom-yellow{
    background-color: $yellow;
}

.triangle{
    width: 0;
    height: 0;
    border-style: solid;
    // border-width: 400px 90px 0 0;
    // border-color: $pink transparent transparent transparent;
}
.triangle-black{
    border-color: black transparent transparent transparent;
}
.triangle-gray{
    border-color:  $gray transparent transparent transparent;
}
.triangle-darkgray{
    border-color: $darkgray transparent transparent transparent;
}
.triangle-midgray{
    border-color: $midgray transparent transparent transparent;
}
.triangle-lightgray{
    border-color: $lightgray transparent transparent transparent;
}
.triangle-pink, .triangle-recommended-pink{
    border-color: $pink transparent transparent transparent;
}
.triangle-green{
    border-color: $green transparent transparent transparent;
}
.triangle-blue{
    border-color: $blue transparent transparent transparent;
}
.triangle-yellow{
    border-color: $yellow transparent transparent transparent;
}
</style>