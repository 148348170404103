<template>
  <div>
                
    
    <v-list dense>
        <v-list-item>
            <v-list-item-content>

                <v-data-table
                dense
                :headers="table.headers"
                :items="getRecords()"
                hide-default-footer
                disable-pagination
                />
                
            </v-list-item-content>
        </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
    name: "RadioReport",
    props:{
        meta: {
            Type: Object,
            required: true
        },
        answers: {
            Type: Array,
            required: true
        },
        question:{
            Type: Object,
            required: true
        },
        language:{
            Type: String,
            required: false,
            default: 'en-ca'
        }
    },
    methods: {
        getRecords(){
            return [this.calculations.answers]
        }
    },
    computed:{
        choices(){
            return this.question.choices
        },
        questionAnswers(){
            let data = {}
            let sectionId = this.meta.section.id
            let questionId = this.question.id

            let submissions = this.answers
            for(let i=0; i<submissions.length; i++){
                let record = submissions[i]
                if(!data[record.user_id]){
                    data[record.user_id] = null
                }
                let answer = record.answers[sectionId][questionId]
                data[record.user_id] = answer
            }

            return data
        },
        calculations(){
            let container = {
                counter: 0,
                answers: {}
            }
            for(let i=0 ;i<this.choices.length; i++){
                let choice = this.choices[i]
                container.answers[choice.value] = 0
            }   

            for(let user_id in this.questionAnswers){
                let submission = this.questionAnswers[user_id]
                let answer = submission.answers ? submission.answers : null
                if(answer!=null){
                    container.counter++
                    container.answers[answer.value]++
                }
            }

            let record = container
            if(record.counter>0){
                let answers = record.answers
                for(let value in answers){
                    answers[value] = parseFloat((answers[value]/record.counter).toFixed(2),10)*100
                }
            }
            
            return container
        },
        table(){
            let headers = []
            for(let i=0; i<this.choices.length; i++){
                let item = this.choices[i]
                headers.push({
                    text: item.text[this.language],
                    value: item.value
                })
            }
            return {
                headers
            }
        }
    }
}
</script>

<style>

</style>