<template>
  <v-card flat>
    <v-card-text>
        <!-- <v-btn text x-small @click="question.controller.reset">Reset</v-btn>
        <v-btn text x-small @click="question.controller.randomize">randomize</v-btn> -->
        <component v-if="isComponent(question.type)" v-model="model[question.id]" @resetInput="resetInput" :rules="rules" :is="question.type" :questionSet="questionSet" :section="section" :question="question" :language="language" :debug="debug"/>
        <json-viewer v-else :value="question"/>
    </v-card-text>
  </v-card>
</template>

<script>
import RatingTable from './ratingTable'
import Radio from './radio'
import Textarea from './textarea'
export default {
    created(){
        function controller(question){
            this.reset = ()=>{
                if(typeof question.$q.reset=='function'){
                    question.$q.reset()
                }
            }
            this.randomize = ()=>{
                if(typeof question.$q.randomize=='function'){
                    question.$q.randomize()
                }
            }
        }
        let question = this.question
        let model = this.model
        this.$set(model,question.id,{})
        question.controller = new controller(question)
    },
    props: {
        rules: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        questionSet:{
            type: Object,
            required: true
        },
        section:{
            type: Object,
            required: true
        },
        question:{
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        RatingTable,
        Radio,
        Textarea
    },
    methods: {
        isComponent(component){
            return this._components[component] != undefined
        },
        resetInput(uid){
            this.$emit('resetInput',uid)
        }
    },
    computed: {
        _components(){
            return {
                RatingTable,
                Radio,
                Textarea
            }
        },
        model(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>